import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'male', 'female'];

  initialize() {
    if (this.inputTarget.value === 'm') {
      this.selectMale();
    }
    if (this.inputTarget.value === 'f') {
      this.selectFemale();
    }
  }

  clickMale() {
    this.inputTarget.value = 'm';
    this.selectMale();
  }

  selectMale() {
    this.maleTarget.classList.add('z-10', 'outline-none', 'ring-1',
                                  'ring-indigo-500', 'border-indigo-500');
    this.femaleTarget.classList.remove('z-10', 'outline-none', 'ring-1',
                                       'ring-indigo-500', 'border-indigo-500');
  }

  clickFemale() {
    this.inputTarget.value = 'f';
    this.selectFemale();
  }

  selectFemale() {
    this.femaleTarget.classList.add('z-10', 'outline-none', 'ring-1',
                                    'ring-indigo-500', 'border-indigo-500');
    this.maleTarget.classList.remove('z-10', 'outline-none', 'ring-1',
                                     'ring-indigo-500', 'border-indigo-500');
  }
}
