import {Controller} from 'stimulus';
import axios from "axios";

const timeout = 30000 // 30000 ms

export default class extends Controller {
    static targets = ['frame', 'price', 'priceYearly', 'recalculationFields', 'deposit', 'newDepositAmount',
        'depositHidden', 'ibanModal', 'checkFirst', 'checkSecond', 'checkThird',
        'rentAmount', 'errorNewDepositAmount', 'priceButton', 'testButton', 'spinnerOne', 'spinnerTwo', 'spinnerThree',
        'documents', 'modalContent', 'modalError'];

    getPriceTimeout;
    hashedId;
    href;
    transactionTotal;

    initialize = () => {
        this.href = window.location.href;
        this.hashedId = window.hashId;
        this.startTime = new Date().getTime();

        this.showTestButtonForDev();
        this.getPriceContinually();
    }

    getPriceContinually = async () => {
        let {state, price, documents} = await this.getPrice();
        if (this.timeIsUp()) {
            window.location.href = '/pending'
            return;
        }

        this.lastState = state;
        switch (state) {
            case 'alteos_submit':
                this.checkFirstTarget.classList.remove('hidden');
                this.spinnerOneTarget.classList.add('hidden');
                setTimeout(() => {
                    this.checkSecondTarget.classList.remove('hidden');
                    this.spinnerTwoTarget.classList.add('hidden');
                }, 3000);
                setTimeout(() => {
                    this.checkThirdTarget.classList.remove('hidden');
                    this.spinnerThreeTarget.classList.add('hidden');
                }, 6000);
                this.getPriceContinuallyWithTimeout();
                break;
            case 'alteos_accepted':
                // only do this on the next page (on the danke page, the first request can still be 'alteos_accepted')
                if (window.location.href.includes('next')) {
                    this.handleAlteosAccepted(price);
                } else {
                    this.getPriceContinuallyWithTimeout();
                }
                break;
            case 'alteos_rejected':
                window.location.href = '/sorry'
                break;
            case 'customer_accepted':
                this.modalContentTarget.classList.remove('hidden');
                this.modalErrorTarget.classList.add('hidden');
                this.checkFirstTarget.classList.remove('hidden');
                this.spinnerOneTarget.classList.add('hidden');
                this.getPriceContinuallyWithTimeout();
                break;
            case 'documents_pending':
            case 'policy_id_pending':
                this.checkSecondTarget.classList.remove('hidden');
                this.spinnerTwoTarget.classList.add('hidden');
                this.getPriceContinuallyWithTimeout();
                break;
            case 'finished':
                this.insertDocuments(documents);
                this.checkThirdTarget.classList.remove('hidden');
                this.spinnerThreeTarget.classList.add('hidden');
                this.frameTarget.classList.add('hidden');
                break;
            case 'error':
                this.modalContentTarget.classList.add('hidden');
                this.modalErrorTarget.classList.remove('hidden');
                this.getPriceContinuallyWithTimeout();
                break;
            case 'error_critical':
                window.location.href = '/error'
                break;
            default:
                this.getPriceContinuallyWithTimeout();
        }
    }

    getPrice = async () => {
        const response = await axios.post('/api/status', {
            id: this.hashedId
        }).catch(function (error) {
            console.log('error ' + error);
        });
        return {
            state: response.data.state,
            price: response.data.price?.total,
            documents: response.data.documents,
        };
    };

    getPriceContinuallyWithTimeout = () => {
        this.getPriceTimeout = setTimeout(() => this.getPriceContinually(), 1000);
    };

    insertDocuments = (documents) => {
        for (const singleDocument of documents) {
            let aTag = document.createElement('a');
            aTag.setAttribute('href', singleDocument.url);
            aTag.setAttribute('target', '_blank');
            aTag.setAttribute('rel', 'noopener noreferrer')
            aTag.setAttribute('class', 'block mt-6 underline text-green-400');
            aTag.textContent = singleDocument.name;
            this.documentsTarget.appendChild(aTag);
        }
    };

    handleAlteosAccepted = (price) => {
        this.priceTarget.textContent = parseFloat(price) < 39 ? "3,25" : (parseFloat(price) / 12).toFixed(2).replace('.', ',');
        this.priceButtonTarget.textContent = parseFloat(price) < 39 ? "39,00" : price;
        this.priceYearlyTarget.textContent = parseFloat(price) < 39 ? "39,00" : price;
        this.transactionTotal = parseFloat(price) < 39 ? "39,00" : price;
        this.setSofortzusage();
        this.setCartUpdate();
        this.newDepositAmountTarget.disabled = false
        this.modalContentTarget.classList.remove('hidden');
        this.modalErrorTarget.classList.add('hidden');
        this.checkFirstTarget.classList.add('hidden');
        this.checkSecondTarget.classList.add('hidden');
        this.checkThirdTarget.classList.add('hidden');
        this.frameTarget.classList.add('hidden');
    };

    toggleRecalculationFields = () => {
        this.recalculationFieldsTarget.classList.toggle('hidden');
    }

    showIsCalculatingModal = () => {
        this.newDepositAmountTarget.disabled = true;
        this.frameTarget.classList.remove('hidden');

        this.checkFirstTarget.classList.add('hidden');
        this.spinnerOneTarget.classList.remove('hidden');
        this.checkSecondTarget.classList.add('hidden');
        this.spinnerTwoTarget.classList.remove('hidden');
        this.checkThirdTarget.classList.add('hidden');
        this.spinnerThreeTarget.classList.remove('hidden');
        this.modalContentTarget.classList.remove('hidden');
        this.modalErrorTarget.classList.add('hidden');
    }

    updateDepositAmountFields = (newDepositAmount) => {
        this.depositTarget.textContent = newDepositAmount;
        this.depositHiddenTarget.value = newDepositAmount;
    }

    triggerChangeDeposit = async (newDepositAmount) => {
        await axios.post('/api/change', {
            id: this.hashedId,
            deposit: newDepositAmount
        }).catch(function (error) {
            console.log('error ' + error);
        });
    }

    depositHasError = (newDepositAmount) => {
        const rentAmount = parseFloat(this.rentAmountTarget.textContent.replace(',', '.'));

        if (newDepositAmount > 3 * rentAmount || newDepositAmount < 300 || newDepositAmount > 7500) {
            this.errorNewDepositAmountTarget.classList.remove('hidden');
            return true;
        }

        return false;
    }

    recalculatePrice = async (event) => {
        this.errorNewDepositAmountTarget.classList.add('hidden');
        const newDepositAmount = parseFloat(this.newDepositAmountTarget.value.replace(',', '.'));

        if (event.keyCode !== 13 && event.button !== 0 || !newDepositAmount || this.depositHasError(newDepositAmount)) {
            return;
        }

        this.toggleRecalculationFields();
        this.showIsCalculatingModal();
        await this.triggerChangeDeposit(newDepositAmount);
        await this.getPriceContinually();
        this.updateDepositAmountFields(newDepositAmount);
    }

    setSofortzusage = () => {
        window.dataLayer.push({
            ecommerce: null
        });
        window.dataLayer.push({
            'event': 'add_to_cart',
            'transactionId': this.hashedId,
            'transactionTotal': parseFloat(this.transactionTotal),
            ecommerce: {
                transaction_id: this.hashId,
                value: this.transactionTotal,
                tax: 0,
                shipping: 0,
                currency: "EUR",
                items: [{
                    item_name: 'Mietkautionsbürgschaft',
                    item_id: '1',
                    price: parseFloat(this.transactionTotal),
                    item_brand: 'heysafe',
                    quantity: '1'
                }]
            }
        });
    }

    setCartUpdate = () => {
        window.dataLayer.push({
            ecommerce: null
        });
        window.dataLayer.push({
            'event': 'cart_update',
            'transactionTotal': parseFloat(this.transactionTotal),
            ecommerce: {
                transaction_id: this.hashId,
                value: this.transactionTotal,
                tax: 0,
                shipping: 0,
                currency: "EUR",
                items: [{
                    item_name: 'Mietkautionsbürgschaft',
                    item_id: '1',
                    price: parseFloat(this.transactionTotal),
                    item_brand: 'heysafe',
                    quantity: '1'
                }]
            }
        });
    }

    buttonClick() {
        this.handleAlteosAccepted('40')
        clearTimeout(this.getPriceTimeout);
    }

    buttonClickDankePage() {
        this.frameTarget.classList.add('hidden');
        clearTimeout(this.getPriceTimeout);
    }

    showTestButtonForDev = () => {
        if (this.href.includes('reports.test') || this.href.includes('dev.heysafe.de')) {
            this.testButtonTarget.classList.remove('hidden');
        }
    }

    timeIsUp() {
        const now = new Date().getTime();
        return timeout <= (now - this.startTime);
    }
}
