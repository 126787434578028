import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ['mobileMenuContainer', 'openMenuButton', 'accountMenu', 'accountMenuButton'];

    connect() {
        document.addEventListener('click', this.handleClickOutside);
    }

    disconnect() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    openMobileMenu = () => {
        if (this.hasMobileMenuContainerTarget && this.hasOpenMenuButtonTarget) {
            this.openMenuButtonTarget.setAttribute('aria-expanded', true);
            this.mobileMenuContainerTarget.classList.remove('hidden');
        }
    }
    
    closeMobileMenu = () => {
        if (this.hasMobileMenuContainerTarget && this.hasOpenMenuButtonTarget) {
            this.openMenuButtonTarget.setAttribute('aria-expanded', false);
            this.mobileMenuContainerTarget.classList.add('hidden');
        }
    }
    
    toggleAccountMenu = () => {
        if (this.hasAccountMenuTarget && this.hasAccountMenuButtonTarget) {
            const isExpanded = this.accountMenuButtonTarget.getAttribute('aria-expanded') === 'true';
            this.accountMenuButtonTarget.setAttribute('aria-expanded', !isExpanded);
            this.accountMenuTarget.classList.toggle('hidden');
        }
    }
    
    closeAccountMenu = () => {
        if (this.hasAccountMenuTarget && this.hasAccountMenuButtonTarget) {
            this.accountMenuButtonTarget.setAttribute('aria-expanded', false);
            this.accountMenuTarget.classList.add('hidden');
        }
    }
    
    handleClickOutside = (event) => {
        if (this.hasMobileMenuContainerTarget && this.hasOpenMenuButtonTarget) {
            const isClickInsideMobileMenu = this.mobileMenuContainerTarget.contains(event.target);
            const isClickOnOpenMenuButton = this.openMenuButtonTarget.contains(event.target);
    
            if (!isClickInsideMobileMenu && !isClickOnOpenMenuButton) {
                this.closeMobileMenu();
            }
        }
    
        if (this.hasAccountMenuTarget && this.hasAccountMenuButtonTarget) {
            const isClickInsideAccountMenu = this.accountMenuTarget.contains(event.target);
            const isClickOnAccountMenuButton = this.accountMenuButtonTarget.contains(event.target);
    
            if (!isClickInsideAccountMenu && !isClickOnAccountMenuButton) {
                this.closeAccountMenu();
            }
        }
    }   
    
}
