import {Controller} from 'stimulus';
import validation_controller from "./validation_controller";
import dayjs from "dayjs";

export default class extends Controller {
    datepicker;
    validationController;

    static targets = ['datepickerContainer', 'dateInput'];

    initialize() {
        const hundredTwentyYearsAgo = dayjs().subtract(120, 'year').toDate();
        const eighteenYearsAgo =dayjs().subtract(18, 'year').toDate();
        const twentyYearsAgo = dayjs().subtract(20, 'year').toDate();
        const sixMonthsAgo = dayjs().subtract(6, 'month').toDate();
        const sixtyDaysInTheFuture = dayjs().add(60, 'day').toDate();
        const minDate = this.dateInputTarget.id === 'start_birthdate' ? hundredTwentyYearsAgo : sixMonthsAgo;
        const maxDate = this.dateInputTarget.id === 'start_birthdate' ? eighteenYearsAgo : sixtyDaysInTheFuture;
        const startView = this.dateInputTarget.id === 'start_birthdate' ? 3 : 0;
        const defaultViewDate = this.dateInputTarget.id === 'start_birthdate' ? twentyYearsAgo : new Date();


        this.validationController = new validation_controller();
        this.datepicker = new Datepicker(this.datepickerContainerTarget, {
            format: 'dd.mm.yyyy',
            minDate: minDate,
            maxDate: maxDate,
            startView: startView,
            weekStart: 1,
            defaultViewDate: defaultViewDate,
        });
        this.datepickerContainerTarget.addEventListener('changeDate', this.changeDate);
    }

    showDatepicker = () => {
        this.datepickerContainerTarget.classList.remove('hidden');

        const topToBirthdateBottom = this.dateInputTarget.getBoundingClientRect().bottom;
        const topToBirthdateTop = this.dateInputTarget.getBoundingClientRect().top;
        const heightOfDatepicker = this.datepickerContainerTarget.offsetHeight;
        const placeDatepickerTopOfBirthdate = topToBirthdateTop - heightOfDatepicker + window.pageYOffset;
        const placeDatepickerBottomOfBirthdate = topToBirthdateBottom + window.pageYOffset;

        if ((topToBirthdateTop - heightOfDatepicker) >= 0) {
            this.datepickerContainerTarget.style.top = `${placeDatepickerTopOfBirthdate}px`;
        } else {
            this.datepickerContainerTarget.style.top = `${placeDatepickerBottomOfBirthdate}px`;
        }
    }

    hideDatepicker = (e)=> {
        if (!this.datepickerContainerTarget.contains(e.relatedTarget) ) {
            this.datepickerContainerTarget.classList.add('hidden');
        }
    }

    changeDate = () => {
        const pickedDate = this.datepicker.getDate('dd.mm.yyyy');
        this.dateInputTarget.value = pickedDate
        this.validationController.validate(this.datepicker.getDate(),this.dateInputTarget.id,this.dateInputTarget.classList,this.dateInputTarget.parentElement);

        this.datepickerContainerTarget.classList.add('hidden');
    }
}

