import {Controller} from 'stimulus';
import axios from "axios";

export default class extends Controller {
    static targets = ['ibanModal', 'bankname', 'banknameContainer', 'helptextContainer', 'questionIcon'];

    closeModal = () => {
        this.ibanModalTarget.classList.add('hidden');
    }

    getBankname = async (event, iban) => {
        const response = await axios.get('/api/banknames', {params: {iban: iban}});
        if (response.hasOwnProperty('data') && Array.isArray(response.data)) {
            return response.data[0];
        }
        return null;
    }

    async showBankname(event) {
        const iban = event.currentTarget.value;

        if (iban.length >= 12) {
            const bankname = await this.getBankname(event, iban);
            if (bankname) {
                this.banknameContainerTarget.classList.remove('hidden');
                this.banknameTarget.textContent = bankname;
            } else {
                this.banknameContainerTarget.classList.add('hidden');
            }
        } else {
            this.banknameContainerTarget.classList.add('hidden');
        }
    }

    showHelptext = () => {
        this.helptextContainerTarget.classList.remove('hidden');
        this.questionIconTarget.classList.remove('text-gray-300');
        this.questionIconTarget.classList.add('text-green-400');
    }

    hideHelptext = () => {
        this.helptextContainerTarget.classList.add('hidden');
        this.questionIconTarget.classList.remove('text-green-400');
        this.questionIconTarget.classList.add('text-gray-300');
    }
}
