import {Controller} from 'stimulus';
import {priceRate, minimumPrice} from "../js/common/utils/pricing";

export default class extends Controller {
    static targets = ['monthlyRate', 'yearlyRate', 'depositAmount'];

    initialize = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const deposit = urlParams.get('deposit');

        const yearlyRate = deposit < 1000 ? minimumPrice : (deposit / 100 * priceRate);
        const monthlyRate = (yearlyRate / 12);

        this.yearlyRateTarget.textContent = yearlyRate.toFixed(2) + ' €';
        this.monthlyRateTarget.textContent = monthlyRate.toFixed(2) + ' €';
    }

    recalculatePaymentRates = () => {
        const isValidNumber = /^[0-9,.]*$/.test(this.depositAmountTarget.value);

        if (isValidNumber) {
            const depositAmount =  this.depositAmountTarget.value.replace(',', '.');
            const yearlyRate = depositAmount < 1000 ? minimumPrice : parseFloat((depositAmount / 100 * priceRate));
            const monthlyRate = parseFloat((yearlyRate / 12));
            this.yearlyRateTarget.textContent = isNaN(yearlyRate) ? '...' : yearlyRate.toFixed(2) + ' €';
            this.monthlyRateTarget.textContent = isNaN(monthlyRate) ? '...' : monthlyRate.toFixed(2) + ' €';
        } else {
            this.yearlyRateTarget.textContent = '...';
            this.monthlyRateTarget.textContent = '...';
        }
    }

}
