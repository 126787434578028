import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ['helptextContainer', 'helptextContainerMobile', 'questionIcon'];

    isMobile = () => {
        const viewportWidth = window.innerWidth;
        return viewportWidth <= 640;
    }

    showHelptext = () => {
        if (this.isMobile()) {
            this.helptextContainerMobileTarget.classList.remove('hidden');
            setTimeout(() => { this.helptextContainerMobileTarget.classList.add('slideUp'); }, 1);
        } else {
            this.helptextContainerTarget.classList.remove('hidden');
        }
        this.questionIconTarget.classList.remove('text-gray-300');
        this.questionIconTarget.classList.add('text-green-400');
    }

    hideHelptext = () => {
        if (this.isMobile()) {
            this.helptextContainerMobileTarget.classList.remove('slideUp')
            this.helptextContainerMobileTarget.classList.add('hidden');
        } else {
            this.helptextContainerTarget.classList.add('hidden');
        }
        this.questionIconTarget.classList.remove('text-green-400');
        this.questionIconTarget.classList.add('text-gray-300');
    }
}
