import {Controller} from 'stimulus';

export default class extends Controller {

    toggleContent = (event) => {
        const iconId = event.currentTarget.dataset.iconId;
        const contentId = event.currentTarget.dataset.contentId;

        document.getElementById(iconId).classList.toggle('fa-rotate-180');
        document.getElementById(contentId).classList.toggle('hidden');
    }
}
