import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'icon', 'submenu', 'mobileMenu', 'mobileMenuClose'];

  initialize() {
    this.moreOpen = false;
    this.mobileOpen = true;
  }

  toggleMoreMenu() {
    if (this.moreOpen) {
      // close the menu
      this.buttonTarget.classList.remove('text-gray-900');
      this.buttonTarget.classList.add('text-gray-500');
      this.iconTarget.classList.remove('text-gray-600');
      this.iconTarget.classList.add('text-gray-400');
      this.submenuTarget.classList.remove('ease-out', 'duration-200');
      this.submenuTarget.classList.add('ease-in', 'duration-150');
      this.submenuTarget.classList.remove('opacity-100', 'translate-y-0', 'block');
      this.submenuTarget.classList.add('opacity-0', 'translate-y-1', 'hidden');
    } else {
      // open the menu
      this.buttonTarget.classList.remove('text-gray-500');
      this.buttonTarget.classList.add('text-gray-900');
      this.iconTarget.classList.remove('text-gray-400');
      this.iconTarget.classList.add('text-gray-600');
      this.submenuTarget.classList.remove('ease-in', 'duration-150');
      this.submenuTarget.classList.add('ease-out', 'duration-200');
      this.submenuTarget.classList.add('opacity-100', 'translate-y-0', 'block');
      this.submenuTarget.classList.remove('opacity-0', 'translate-y-1', 'hidden');
    }

    this.moreOpen = !this.moreOpen;
  }

  closeMobileMenu() {
    this.mobileOpen = false;
    this.mobileMenuTarget.classList.remove('duration-200', 'ease-out');
    this.mobileMenuTarget.classList.add('duration-100', 'ease-in');
    this.mobileMenuTarget.classList.remove('opacity-100', 'scale-100')
    this.mobileMenuTarget.classList.add('opacity-0', 'scale-95');
  }
}
