import {Controller} from 'stimulus';


export default class extends Controller {
  static targets = [
    'input',
    'current',
    'future',
    'landlord',
    'digital',
    'landlordAddress',
    'buttonsContainer'];

  landlordAddressFields = {
    'next[landlordAddressCode]': '',
    'next[landlordCity]': '',
    'next[landlordHouseNumber]': '',
    'next[landlordStreetName]': '',
  };

  initialize() {
    if (this.inputTarget.value === 'c') {
      this.selectCurrent();
    }
    if (this.inputTarget.value === 'f') {
      this.selectFuture();
    }
    if (this.inputTarget.value === 'l') {
      this.selectLandlord();
    }
    if (this.inputTarget.value === 'd') {
      this.selectDigital();
    }
  }

  clickCurrent() {
    this.inputTarget.value = 'c';
    this.selectCurrent();
  }

  selectCurrent() {
    this.currentTarget.classList.add('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.futureTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.landlordTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.digitalTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
  }

  clickFuture() {
    this.inputTarget.value = 'f';
    this.selectFuture();
  }

  selectFuture() {
    this.futureTarget.classList.add('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.currentTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.landlordTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.digitalTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
  }

  clickLandlord() {
    this.inputTarget.value = 'l';
    this.selectLandlord();
  }

  selectLandlord() {
    this.landlordTarget.classList.add('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.currentTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.futureTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.digitalTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
  }

  clickDigital() {
    this.inputTarget.value = 'd';
    this.selectDigital();
  }

  selectDigital() {
    this.digitalTarget.classList.add('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.currentTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.landlordTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
    this.futureTarget.classList.remove('z-10', 'outline-none', 'ring-1',
        'ring-indigo-500',
        'border-indigo-500');
  }

  emptyLandlordAddressFields = () => {
    const landlordAddressInputs = this.landlordAddressTarget.getElementsByTagName(
        'input');
    for (let input of landlordAddressInputs) {
      this.landlordAddressFields[input.name] = input.value;
      input.value = null;
    }
  };

  fillLandlordAddressFields = () => {
    const landlordAddressInputs = this.landlordAddressTarget.getElementsByTagName(
        'input');
    for (let input of landlordAddressInputs) {
      input.value = this.landlordAddressFields[input.name];
    }
  };

  showLandlordAddress = () => {
    this.landlordAddressTarget.classList.remove('hidden');
    this.fillLandlordAddressFields();
  };

  removeLandlordAddress = () => {
    this.landlordAddressTarget.classList.add('hidden');
    this.emptyLandlordAddressFields();
  };

  showDestinationPossiblities = () => {
    this.buttonsContainerTarget.classList.toggle('hidden');
  }

}
