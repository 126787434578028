import {Controller} from 'stimulus';
import {schema} from '../js/application/utils/ValidationSchema';
import * as yup from 'yup';

let allFieldsValid = true;

export default class extends Controller {
    static targets = [
        'form',
        'termsAccepted',
        'buttonsContainer',
        'hiddenInput',
        'depositAmount',
        'ibanModal',
        'iban',
        'sepaTerms',
        'sepaTermsErrorAnchor',
        'priceButton',
        'landlordEmail',
    ];

    fieldsNotToValidate = [
        'start__token',
        'start_termsAccepted',
        'start_contactAllowed',
        'start_gender',
        'next_destAddress',
        'next_IBAN',
        'next_sepaTerms',
        'next__token',
    ];

    isDateField = (inputField) => {
        return inputField.id === 'start_birthdate' ||
            inputField.id === 'next_moveInDate';
    };

    toggleIbanModal = () => {
        this.ibanModalTarget.classList.toggle('hidden');
    };

    createErrorNode = (errorMessage, id) => {
        const errorNode = document.createElement('div');
        errorNode.setAttribute('id', `error-${id}`);
        errorNode.classList.add('md:flex', 'md:items-center', 'mb-4');

        const unorderedList = document.createElement('ul');
        unorderedList.classList.add('list-reset');

        const listItem = document.createElement('li');
        listItem.setAttribute('id', `list-${id}`);
        listItem.classList.add('mt-2', 'text-sm', 'text-red-600');
        listItem.innerText = errorMessage;

        unorderedList.appendChild(listItem);
        errorNode.appendChild(unorderedList);

        return errorNode;
    };

    validate = async (value, id, classList, errorAnchor) => {
        let isValid = true;
        let errorMessage = '';
        const oldErrorMessage = document.getElementById(`list-${id}`)?.innerText;

        await yup.reach(schema, id).validate(value).catch(function (error) {
            isValid = false;
            allFieldsValid = false;
            errorMessage = error.errors;
        });

        const errorNode = errorMessage && this.createErrorNode(errorMessage, id);
        const errorNodeById = document.getElementById(`error-${id}`);

        isValid ? classList.remove('border-red-300', 'border-2') : classList.add(
            'border-red-300', 'border-2');
        ((isValid && errorNodeById) ||
            (!isValid && errorNodeById && (oldErrorMessage !== errorMessage))) &&
        errorNodeById.remove();
        !isValid && (!errorNodeById || oldErrorMessage !== errorMessage) &&
        errorAnchor.insertAdjacentElement('afterend', errorNode);
    };

    validateInputField = async (event) => {
        const inputField = event.target;
        const fieldValue = this.isDateField(inputField) ? new Date(
            inputField.value.split('.').reverse().join('-')) : inputField.value;

        await this.validate(fieldValue, inputField.id, inputField.classList,
            inputField.parentElement);
    };

    validateHiddenInputField = async () => {
        const hiddenInput = this.hiddenInputTarget;
        const buttonsContainer = this.buttonsContainerTarget;
        await this.validate(hiddenInput.value, hiddenInput.id,
            buttonsContainer.classList, buttonsContainer.parentElement);
    };

    validateLandlordEmail = async () => {
        const landlordEmail = this.landlordEmailTarget;
        await this.validate(landlordEmail.value, landlordEmail.id,
            landlordEmail.classList, landlordEmail.parentElement);
    };

    validateCheckbox = async (event) => {
        const checkbox = event.target;
        await this.validate(checkbox.checked, checkbox.id, checkbox.classList,
            this.termsAcceptedTarget);
    };

    //TODO geht das nicht auch über das validateHiddenInputField?!
    validateDepositAmount = async () => {
        const depositAmount = this.depositAmountTarget;
        depositAmount.value &&
        await this.validate(depositAmount.value, depositAmount.id, depositAmount.classList, depositAmount.parentElement);
    };

    validateIbanFields = async () => {
        allFieldsValid = true;

        const iban = this.ibanTarget;
        const sepaTerms = this.sepaTermsTarget;
        await this.validate(iban.value, iban.id, iban.classList, iban.parentElement);
        await this.validate(sepaTerms.checked, sepaTerms.id, sepaTerms.classList, this.termsAcceptedTarget);

        return allFieldsValid;
    };

    validateAll = async () => {
        allFieldsValid = true;
        const formFields = Array.from(this.formTarget.querySelectorAll('input'));
        const formFieldsToValidate = formFields.filter(formField => !this.fieldsNotToValidate.includes(formField.id));
        const termsAccepted = this.formTarget.querySelector('#start_termsAccepted');

        for (const field of formFieldsToValidate) {
            const fieldValue = this.isDateField(field) ? new Date(
                field.value.split('.').reverse().join('-')) : field.value;
            await this.validate(fieldValue, field.id, field.classList,
                field.parentElement);
        }

        termsAccepted &&
        await this.validate(termsAccepted.checked, termsAccepted.id,
            termsAccepted.classList, this.termsAcceptedTarget);
        await this.validateHiddenInputField();

        return allFieldsValid;
    };

    showIbanModal = async () => {
        const isValid = await this.validateAll();
        isValid && this.toggleIbanModal();
    }

    submitStartForm = async (event) => {
        event.preventDefault();
        const isValid = await this.validateAll();
        isValid && this.formTarget.submit();
    };

    submitNextForm = async (event) => {
        event.preventDefault();
        const isValid = await this.validateIbanFields();
        if (isValid) {
            this.formTarget.submit();
            this.setOrderConfirmation();
        }
    }

    setOrderConfirmation = () => {
        const transactionTotal = parseFloat(this.priceButtonTarget.textContent.replace(',', '.')); //todo wollen wir jahres oder monatspreis in piwik speichern?
        window.dataLayer.push({ 
            ecommerce: null 
        });
        window.dataLayer.push({
            'event': 'order_confirmation',
            'transactionId': window.orderId,
            'transactionTotal': transactionTotal,
        });
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                transaction_id: window.orderId,
                value: transactionTotal,
                tax: 0,
                shipping: 0,
                currency: "EUR",
                items: [{
                    item_name: 'Mietkautionsbürgschaft',
                    item_id: window.hashId,
                    price: parseFloat(this.transactionTotal),
                    item_brand: 'heysafe',
                    quantity: '1'
                }]
            }
        });
    }
}